import logo from './logo.svg';
import AppRouter from './AppRouter'

function App() {
  return (
    <AppRouter />
  );
}

export default App;
